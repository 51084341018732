<bx-page-message xmlns="http://www.w3.org/1999/html">
  @if (uiIndexState) {
    <div class="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
      @if (
        uiIndexState.currentIndexState === IndexStateEnum.ABSENT ||
        uiIndexState.currentIndexState === IndexStateEnum.REINDEXING_ERROR
      ) {
        <div>
          <bx-ngs-re-index-tables
            [selectedTable]="selectedTable"
            [currentState]="uiIndexState.currentIndexState"
            [additionalTablesToBeReIndexed]="additionalTablesToBeReIndexed"
            [isGraph]="isGraph"
          ></bx-ngs-re-index-tables>
        </div>
      } @else if (uiIndexState.currentIndexState === IndexStateEnum.RESTORING_ERROR) {
        <h3>An error occurred while restoring {{ isGraph ? 'graph' : 'table' }}</h3>
        <p>
          Please contact support
          <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">here</a>
        </p>
      } @else if (uiIndexState.currentIndexState === IndexStateEnum.RESTORING) {
        <div>
          <h3>Restoring your {{ isGraph ? 'graph' : 'table' }} from storage</h3>
          <p>
            This {{ isGraph ? 'graph' : 'table' }} needs to be restored as it was not accessed for
            {{ ARCHIVE_INDICES_AFTER }}.
          </p>
        </div>
      } @else if (uiIndexState.currentIndexState === IndexStateEnum.REINDEXING) {
        <div>
          <h3>Restoring your {{ isGraph ? 'graph' : 'table' }} from archive</h3>
          <p>
            This {{ isGraph ? 'graph' : 'table' }} has been archived as it was not accessed for
            {{ DELETE_INDICES_AFTER }}.
          </p>
          <p>
            You can navigate away from this page while the job completes or the
            {{ isGraph ? 'graph' : 'table' }} will be automatically loaded when restoration is
            complete.
          </p>
        </div>
      }
      @if (uiIndexState.progress > -1) {
        <ngb-progressbar
          type="info"
          [value]="uiIndexState.progress"
          [striped]="true"
          [animated]="true"
          [showValue]="true"
          class="w-100"
        >
        </ngb-progressbar>
      }
      <br />
      <bx-ngs-cancel-reindexing [uiIndexState]="uiIndexState"></bx-ngs-cancel-reindexing>
    </div>
  }
</bx-page-message>
