import {
  AggregatedDocumentQuery,
  AggregatedDocumentResponse,
  DocumentServiceDocumentQuery,
} from '../../../../nucleus/services/documentService/document-service.v1.http';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import { SeriesColumnOptions } from 'highcharts';
import { HeatmapData } from '../../../features/graphs/graph-heatmap/graph-heatmap.component';
import { GridData } from '../../../features/graphs/graph-util.service';
import { RowWithGene } from '../../../features/graphs/gene-combinations-heatmap.service';
import { TreeGraphData } from '../../../features/graphs/graph-sidebar';

export type AggregateData = AggregatedDocumentResponse['data'];
export type QueryResultData = IGridResourceResponse<any>;
export const CODON_USAGE_TABLE = 'DOCUMENT_TABLE_CODON_USAGE';
export type ReportWith<
  T extends 'overview' | 'geneCombinations' | 'geneFamilyUsage',
  U extends string,
  V,
> = {
  statistics: Record<T, Record<U, V>>;
};

export const alignmentTypes = ['freeEndGaps', 'nonFreeEndGaps', 'both'] as const;
export type AlignmentType = (typeof alignmentTypes)[number];
export type SimilarityTreeAndMatrix<T extends TreeGraphData | string> = Partial<
  Record<
    AlignmentType,
    {
      similarityTree: T;
      similarityMatrix: number[][];
    }
  >
>;

export interface ClusterSummaryDataFromReport {
  summaryDataByScoreType: SimilarityTreeAndMatrix<string>;
  idToIndex: Record<string, number>;
}

export interface ClusterSummaryData {
  summaryDataByScoreType: SimilarityTreeAndMatrix<TreeGraphData>;
  idToIndex: Record<string, number>;
  sequences: Record<number, { metadata: Record<string, any> }>;
  totalSequences: number;
}

export interface SankeyDataFromQuery {
  clusterTotals: Record<string, number>;
  clusterNames: Record<string, string>;
  clusterMatrix: Record<string, Record<string, number>>;
  clusterFrequencies: Record<string, number>;
  regionTotals: Record<string, number>;
}

export const GraphTypes = {
  None: { id: 'noGraph', name: 'No Graph Selected' },
  ClusterDiversity: { id: 'clusterDiversity', name: 'Cluster Diversity' },
  ClusterLengths: { id: 'clusterLengths', name: 'Cluster Lengths' },
  ClusterSizes: { id: 'clusterSizes', name: 'Cluster Sizes' },
  AnnotationRates: { id: 'annotationRates', name: 'Annotation Rates' },
  ClusterNumbers: { id: 'clusterNumbers', name: 'Number of Clusters' },
  ClusterNumbersNucleotide: {
    id: 'clusterNumbersNucleotide',
    name: 'Number of Clusters (Nucleotide)',
  },
  CodonDistribution: { id: 'codonDistribution', name: 'Codon Distribution Chart' },
  AminoAcidDistribution: { id: 'aminoAcidDistribution', name: 'Amino Acid Distribution Chart' },
  GeneCombinations: { id: 'geneCombinations', name: 'Gene Combinations' },
  GeneFamilyUsage: { id: 'geneFamilyUsage', name: 'Gene Family Usage' },
  NumberOfGenes: { id: 'numberOfGenes', name: 'Number of Genes' },
  Sankey: { id: 'sankey', name: 'Cluster Relationships' },
  ClusterSummary: { id: 'clusterSummaryTree', name: 'Cluster Similarity Tree' },
  ClusterSummaryNetwork: { id: 'clusterSummaryNetwork', name: 'Cluster Similarity Network' },
  ComparisonsHistogram: { id: 'comparisonsHistogram', name: 'Frequency' },
  ComparisonsScatterplot: { id: 'comparisonsScatterplot', name: 'Scatterplot' },
} as const;

export type GraphType = (typeof GraphTypes)[keyof typeof GraphTypes];
export type GraphId = GraphType['id'];

export type GeneFamilyData = Record<string, Record<string, number>>;
type GraphData = {
  noGraph: null;
  clusterDiversity: AggregateData;
  clusterLengths: AggregateData;
  clusterSizes: QueryResultData;
  annotationRates: ReportWith<'overview', 'annotationRates', any[]>;
  numberOfGenes: ReportWith<'overview', 'genes', any[]>;
  clusterNumbers: ReportWith<'overview', 'clusters', any[]>;
  clusterNumbersNucleotide: ReportWith<'overview', 'clusters', any[]>;
  aminoAcidDistribution: SeriesColumnOptions[];
  codonDistribution: { table: GridData; heatmap: HeatmapData };
  geneCombinations: ReportWith<'geneCombinations', string, RowWithGene[]>;
  geneFamilyUsage: ReportWith<'geneFamilyUsage', string, GeneFamilyData | GeneFamilyData[string]>;
  sankey: SankeyDataFromQuery;
  clusterSummaryTree: ClusterSummaryData;
  clusterSummaryNetwork: ClusterSummaryData;
};

export type GraphDataFor<T extends GraphId> = T extends keyof GraphData ? GraphData[T] : never;

type AdditionalSelectionDataTypes = {
  clusterDiversity: AggregatedDocumentQuery;
  clusterLengths: AggregatedDocumentQuery;
  clusterSizes: DocumentServiceDocumentQuery;
  aminoAcidDistribution: { length: number };
  codonDistribution: { length: number | string };
  geneCombinations: {
    computeHeatmapFromTable: boolean;
    tableData: {
      geneCombination: string;
      reduced: boolean;
    };
  };
  sankey: {
    regions: string[];
    topCount: number;
    table: `DOCUMENT_TABLE_${'ALL_SEQUENCES' | 'CHAIN_COMBINATIONS'}`;
  };
};

export type ExtraSelectionDataFor<T extends GraphId> = T extends keyof AdditionalSelectionDataTypes
  ? AdditionalSelectionDataTypes[T]
  : null;

export type NgsGraph = {
  name: string;
  id: GraphId;
};

export const OVERVIEW_GRAPHS: NgsGraph[] = [
  GraphTypes.Sankey,
  GraphTypes.AnnotationRates,
  GraphTypes.GeneCombinations,
  GraphTypes.NumberOfGenes,
  GraphTypes.GeneFamilyUsage,
  GraphTypes.ClusterNumbers,
  GraphTypes.ClusterNumbersNucleotide,
];
export const CLUSTER_GRAPHS: NgsGraph[] = [
  GraphTypes.ClusterSummaryNetwork,
  GraphTypes.ClusterSummary,
  GraphTypes.ClusterDiversity,
  GraphTypes.ClusterLengths,
  GraphTypes.ClusterSizes,
  GraphTypes.CodonDistribution,
  GraphTypes.AminoAcidDistribution,
  GraphTypes.Sankey,
];
export const CLUSTER_GENE_GRAPHS: NgsGraph[] = [
  GraphTypes.ClusterSummaryNetwork,
  GraphTypes.ClusterSummary,
  GraphTypes.ClusterDiversity,
  GraphTypes.ClusterSizes,
  GraphTypes.Sankey,
];

export const CHAIN_COMBINATIONS_GRAPHS: NgsGraph[] = [
  GraphTypes.Sankey,
  GraphTypes.GeneCombinations,
];

export const SelectionDisplayOption = {
  AUTO: { id: 'auto', label: 'Auto' },
  ALL: { id: 'all', label: 'Full dataset' },
  SELECTED: { id: 'selected', label: 'Only selected rows' },
} as const;

export const SelectionDisplayOptions = Object.values(SelectionDisplayOption);
export type SelectionDisplayType =
  (typeof SelectionDisplayOption)[keyof typeof SelectionDisplayOption];
