<bx-page-message>
  <h2 data-spec="table-view-unavailable-message">
    {{ isGraph ? 'Graph' : 'Table view' }} unavailable
  </h2>
  @switch (currentState) {
    @case (IndexStateEnum.REINDEXING_ERROR) {
      <div>
        <p class="mb-4">
          This {{ isGraph ? 'graph' : 'table' }} is unable to be viewed, as indexing did not
          complete successfully.
        </p>
        <p>Please retry,</p>
        <p>
          or contact support
          <a href="{{ HELP_CENTER_URL }}/hc/en-us/requests/new">here</a> if this is not expected.
        </p>
      </div>
    }
    @case (IndexStateEnum.ABSENT) {
      <div>
        <p class="mb-4">
          This {{ isGraph ? 'graph' : 'table' }} has been archived as it was not accessed for
          {{ DELETE_INDICES_AFTER }}.
        </p>
        <p class="mb-4">To access your results faster restore only the required table(s).</p>
      </div>
    }
  }
  <div style="align-items: center">
    <div><b>Suggested actions:</b></div>
    <br />
    <div>
      <div>
        <button class="btn btn-outline-primary" (click)="reIndexRequiredTables()">
          {{ reIndexRequiredTablesLabel }}
        </button>
      </div>
      <br />
      @if ((deIndexedTables$ | async).length > 1) {
        <div>
          <button class="btn btn-outline-primary" (click)="reIndexAllTables()">
            Restore all tables(slower)
          </button>
        </div>
      }
    </div>
  </div>
</bx-page-message>
