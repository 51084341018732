import { combineLatest, Observable, of, of as observableOf, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import {
  geneFamilyNameMap,
  getGeneFamilyDataFromReport,
  NgsReportService,
} from '../../core/ngs/ngs-report-viewer/ngs-report.service';
import { GraphDocumentDataService } from './graph-document-data.service';
import { AbstractGraphDataService } from './abstract-graph-data.service';
import { CleanUp } from '../../shared/cleanup';
import { IBarChartInfo } from './column-chart/BarChartInfo.model';
import { isClusterTable, isInexactClusterTable } from '../../core/ngs/table-type-filters';
import { GraphUtilService } from './graph-util.service';
import { SummaryGraphParams } from '../../core/ngs/ngs-summary-graph-viewer/ngs-summary-graph-viewer.component';
import { HeatmapInfo } from './graph-heatmap/heatmap-info.model';
import { IStackedBarInfo } from './stacked-column-chart/stacked-bar-info.model';
import { sortGeneCombinationName } from '../../shared/sort.util';
import { DocumentTableStateService } from '../../core/document-table-service/document-table-state/document-table-state.service';
import { DocumentTableType } from '../../../nucleus/services/documentService/document-table-type';
import { ChainPrefix, ChainType } from '../../core/antibodyAnnotatorRegions.service';
import { GeneCombinationsHeatmapService, RowWithGene } from './gene-combinations-heatmap.service';
import { DocumentTable } from '../../../nucleus/services/documentService/types';

@Injectable({
  providedIn: 'root',
})
export class GraphAbaDataService extends CleanUp {
  datasource: AbstractGraphDataService;

  constructor(
    private documentTableStateService: DocumentTableStateService,
    private graphDocumentService: GraphDocumentDataService,
    private ngsReportService: NgsReportService,
    private geneCombinationsHeatmapService: GeneCombinationsHeatmapService,
  ) {
    super();

    this.datasource = this.graphDocumentService;
  }

  getStackedBarChartData(
    params: SummaryGraphParams,
    documentID: string,
    options: StackedBarChartGraphOptions,
  ): Observable<IStackedBarInfo> {
    if (params.source === 'aminoAcid') {
      if (!options.region && !options.length) {
        return observableOf({
          title: 'Amino Acid Distribution by Position',
          data: [],
        });
      }
      return this.datasource
        .getAminoAcidDistribution(documentID, options.region, options.length)
        .pipe(
          map((data) => ({
            title: 'Amino Acid Distribution by Position',
            data: data,
          })),
        );
    } else if (params.source === 'json') {
      return this.getStackedBarChartFromJSON(documentID, params.jsonChart, options);
    } else {
      return throwError(() => 'Unsupported table source.');
    }
  }

  getBarChartData(
    params: SummaryGraphParams,
    documentID: string,
    option: GraphOption,
  ): Observable<IBarChartInfo> {
    if (params.source === 'json') {
      return this.getBarChartFromJSON(documentID, option.name, params.jsonChart, params.type);
    } else if (option.table) {
      return this.datasource.getBarChart(documentID, params, option);
    } else {
      // Table not specified - this is likely a transitory state.
      return observableOf({
        title: '',
        xLabel: '',
        yLabel: '',
        data: [],
      });
    }
  }

  getTableUIIndexState(documentID: string, tableName: string) {
    this.documentTableStateService.selectTable(documentID, tableName); // expectation is to  start restoring if the table is archived(not deep)
    return this.documentTableStateService.getUIIndexState(documentID, tableName);
  }

  getFormattedClusterLengths(
    source: string,
    documentID: string,
    region: GraphOption,
  ): Observable<ClusterLengths> {
    const lengths$ = this.datasource.getClusterLengths(documentID, region);
    if (source === 'aminoAcid') {
      return lengths$;
    } else if (source === 'codon') {
      return lengths$.pipe(map((data) => data.concat(new Array<any>('All lengths'))));
    } else {
      return throwError(() => 'Unsupported table source.');
    }
  }

  getHeatmapChartData(
    params: SummaryGraphParams,
    documentID: string,
    options: HeatmapGraphOption,
  ): Observable<HeatmapInfo> {
    if (params.source === 'codon') {
      return this.datasource.getHeatmapChart(documentID, params, options);
    }

    if (params.source === 'json') {
      return this.getHeatmapChartFromJSON(documentID, params, options);
    }

    return throwError(() => 'Unsupported chart data source.');
  }

  getAllBarOptions(documentID: string): Observable<AbaAllChartOptions> {
    const charts: ('annotationRates' | 'clusters' | 'genes')[] = [
      'annotationRates',
      'clusters',
      'genes',
    ];
    const regions$ = this.getOrderedClusterTableRegions(documentID);
    const report$ = this.ngsReportService.getReportBlobByID(documentID);
    return combineLatest([report$, regions$]).pipe(
      map(([report, regions]) => {
        const options: any = {
          regions,
          annotationRates: [],
          clusters: [],
          genes: [],
          geneFamilies: Object.keys(report.statistics.geneFamilyUsage ?? {})
            .map((gene) => {
              return { name: geneFamilyNameMap(gene as GeneFamilyType) };
            })
            .filter(({ name }) => !!name),
          geneCombinations: Object.keys(report.statistics.geneCombinations)
            .sort(sortGeneCombinationName)
            .map((gene) => ({ name: gene })),
        };
        charts
          .map((name) => ({ chart: report.statistics.overview[name], name }))
          .filter((all) => all.chart?.length > 0)
          .forEach((all) => {
            options[all.name] = Object.keys(all.chart[0])
              .filter((header) => header !== 'Region' && header !== 'As % Of')
              .map((header) => ({ name: header }));
          });
        return options;
      }),
    );
  }

  getOrderedClusterTableRegions(documentID: string): Observable<GraphOption[]> {
    return this.documentTableStateService.getTables(documentID).pipe(
      take(1),
      map((tables) => tables.filter(isClusterTable)),
      map((tables) =>
        tables.map((table) => ({
          name: table.displayName,
          table: table.name,
          // Ensuring we assign the correct table type for older inexact cluster table prior to the BX-6573 fix.
          tableType: isInexactClusterTable(table)
            ? DocumentTableType.INEXACT_CLUSTER
            : table.tableType,
          columns: table.columns,
        })),
      ),
    );
  }

  private getBarChartFromJSON(
    documentID: string,
    yAxis: string,
    jsonChart: string,
    type?: string,
  ): Observable<IBarChartInfo> {
    return this.ngsReportService
      .getReportBlobByID(documentID)
      .pipe(
        map((report) => NgsReportService.getBarChartOfTypeWithAxis(report, yAxis, jsonChart, type)),
      );
  }

  private getHeatmapChartFromJSON(
    documentID: string,
    params: SummaryGraphParams,
    options: HeatmapGraphOption,
  ): Observable<HeatmapInfo> {
    const JSONReport = this.ngsReportService.getReportBlobByID(documentID);

    if (params.jsonChart === 'gene_combinations') {
      const sortGeneColumnFirst = (a: string, b: string) => {
        if (a === 'Gene') {
          return -1;
        }

        if (b === 'Gene') {
          return 1;
        }

        return 0;
      };

      return JSONReport.pipe(
        switchMap((report) => {
          if (
            !Object.keys(report.statistics.geneCombinations).includes(options.geneCombination?.name)
          ) {
            return this.geneCombinationsHeatmapService.getHeatmapDataFromChainCombinations(
              documentID,
              'DOCUMENT_TABLE_CHAIN_COMBINATIONS',
              options?.geneCombination?.name,
              options?.reduced,
            );
          } else {
            return of(report.statistics.geneCombinations[options.geneCombination?.name]);
          }
        }),
        map((rawData: RowWithGene[]) => {
          const sharedGraphInfo = GeneCombinationsHeatmapService.getGeneCombinationTitleAndAxes(
            options.geneCombination?.name,
            options.transposed ?? false,
          );

          if (options.isTable) {
            return {
              ...sharedGraphInfo,
              data: GraphUtilService.rowsToTable(rawData, 'Gene', sortGeneColumnFirst),
              type: 'table',
            };
          }
          if (options?.reduced) {
            rawData = GeneCombinationsHeatmapService.collapseHeatmapData(rawData);
          }
          return {
            ...sharedGraphInfo,
            data: NgsReportService.transformHeatmapData(rawData, options?.transposed),
            type: 'heatmap',
          };
        }),
      );
    }

    return throwError(() => 'Unsupported JSON chart');
  }

  private getStackedBarChartFromJSON(
    documentID: string,
    jsonChart: string,
    options: StackedBarChartGraphOptions,
  ): Observable<IStackedBarInfo> {
    const JSONReport = this.ngsReportService.getReportBlobByID(documentID);
    if (jsonChart === 'geneFamilyUsage') {
      return JSONReport.pipe(
        map((report) => {
          if (!options.geneFamily) {
            return [];
          }
          return getGeneFamilyDataFromReport(report, options.geneFamily.name);
        }),
        map((data) => {
          return {
            title: `${options.geneFamily?.name ?? 'Gene'} Family Usage`,
            data: data,
          };
        }),
      );
    }

    return throwError(() => 'Unsupported JSON chart');
  }
}

export interface AbaAllChartOptions {
  regions: GraphOption[];
  annotationRates: GraphOption[];
  clusters: GraphOption[];
  genes: GraphOption[];
  geneFamilies: GraphOption[];
  geneCombinations: GraphOption[];
}

export interface GraphOption extends Pick<DocumentTable, 'tableType' | 'columns' | 'metadata'> {
  name: string;
  table?: string;
}

export interface StackedBarChartGraphOptions {
  length?: number;
  region?: GraphOption;
  geneFamily?: GraphOption;
}

export interface HeatmapGraphOption {
  length?: string | number;
  region?: GraphOption;
  geneCombination?: GraphOption;
  isTable?: boolean;
  transposed?: boolean;
  reduced?: boolean;
}

export type ClusterLengths = (number | 'All lengths')[];

type UnprefixedGeneFamilyType = `All${ChainType}`;
type GeneFamilyType = UnprefixedGeneFamilyType | `${ChainPrefix}: ${UnprefixedGeneFamilyType}`;
