import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  DELETE_INDICES_AFTER,
  IndexStateEnum,
} from '../../document-table-service/document-table-state/document-table-state';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';
import { HELP_CENTER_URL } from '../../../app.constants';

@Component({
  selector: 'bx-ngs-re-index-tables',
  templateUrl: './ngs-re-index-tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, AsyncPipe],
})
export class NgsReIndexTablesComponent implements OnInit, OnChanges {
  @Input() selectedTable: DocumentTable;
  @Input() currentState: IndexStateEnum;
  @Input() additionalTablesToBeReIndexed?: string[];
  @Input() isGraph?: boolean;
  deIndexedTables$: Observable<DocumentTable[]>;
  allTableNames$: Observable<string>;
  reIndexRequiredTablesLabel: string;

  protected readonly IndexStateEnum = IndexStateEnum;
  protected readonly DELETE_INDICES_AFTER = DELETE_INDICES_AFTER;

  constructor(private readonly documentTableStateService: DocumentTableStateService) {}

  ngOnInit(): void {
    this.deIndexedTables$ = this.documentTableStateService.getReIndexingRequiredTables(
      this.selectedTable.documentID,
    );
    this.allTableNames$ = this.deIndexedTables$.pipe(
      map((tables) => tables.map((table) => table.displayName).join(', ')),
    );
  }

  ngOnChanges() {
    this.reIndexRequiredTablesLabel =
      `Restore ${this.selectedTable.displayName} table ` +
      (this.additionalTablesToBeReIndexed && this.additionalTablesToBeReIndexed.length > 0
        ? `and ${this.additionalTablesToBeReIndexed.length} other table(s)`
        : '');
  }

  reIndexRequiredTables() {
    let tableNames = [this.selectedTable.name];
    if (this.additionalTablesToBeReIndexed && this.additionalTablesToBeReIndexed.length > 0) {
      tableNames = tableNames.concat(this.additionalTablesToBeReIndexed);
    }
    this.reIndexTables(tableNames).subscribe(() => {});
  }

  reIndexAllTables() {
    this.deIndexedTables$
      .pipe(
        take(1),
        switchMap((tables) => this.reIndexTables(tables.map((table) => table.name))),
        take(1),
      )
      .subscribe(() => {});
  }

  private reIndexTables(tableNames: string[]) {
    return this.documentTableStateService.reIndexTables(this.selectedTable.documentID, tableNames);
  }

  protected readonly HELP_CENTER_URL = HELP_CENTER_URL;
}
