import {
  GraphControlTypeEnum,
  GraphDatasource,
  GraphDatasourceError,
  GraphDatasourceReIndexingRequired,
  GraphSidebarControls,
  GraphSidebarDatasourceResponse,
  GraphTypeEnum,
} from '../graph-sidebar';
import { AbaAllChartOptions, GraphAbaDataService, GraphOption } from '../graph-aba-data.service';
import { SummaryGraphParams } from '../../../core/ngs/ngs-summary-graph-viewer/ngs-summary-graph-viewer.component';
import { IBarChartInfo } from '../column-chart/BarChartInfo.model';
import { BarChartOptions } from '../../report/report.model';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { isInexactClusterTable } from '../../../core/ngs/table-type-filters';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { hasClusterLengthColumn } from '../../../core/ngs/table-column-filters';
import { IndexStateEnum } from '../../../core/document-table-service/document-table-state/document-table-state';

export default class AbaColumnGraphDatasource implements GraphDatasource {
  constructor(
    private dataService: GraphAbaDataService,
    private readonly documentID: string,
    private tables: Record<string, DocumentTable>,
    private params: SummaryGraphParams,
    private allOptions: AbaAllChartOptions,
    private initialOptions?: BarChartOptions,
  ) {}

  getIdForSavedOptions$() {
    return of({
      id: `${this.documentID}-chart-presenter-column-${this.params.jsonChart ?? ''}${this.params.jsonChart && this.params.type ? '-' : ''}${this.params.type ?? ''}`,
    });
  }

  setInitialOptions(initialOptions: Record<string, any>) {
    const region = this.regions.find((r) => r.name === initialOptions?.region);
    this.initialOptions = region ? { region } : this.initialOptions;
  }

  validate(): GraphDatasourceError | null {
    if (!(this.initialOptions?.region ?? this.regions[0])) {
      return {
        error:
          "No region clusters present. Your sequence filtering options may have been too restrictive for this data set. Please re-run analysis without the 'Only cluster' options turned on.",
        controls: [],
      };
    }
    return null;
  }

  init(): Observable<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return of(validation);
    }
    return this.getData(this.initialOptions?.region ?? this.regions[0]);
  }

  controlValueChanged(
    previousControls: { region: string },
    controls: { region: string },
  ): Observable<GraphSidebarDatasourceResponse> {
    const region = this.regions.find((r) => r.name === controls.region);
    return this.getData(region);
  }

  private get regions(): GraphOption[] {
    if (this.params.source === 'json') {
      return this.allOptions[this.params.jsonChart as keyof AbaAllChartOptions];
    }
    if (this.params.type === 'cluster_lengths') {
      return this.allOptions.regions.filter(
        (region) =>
          !isInexactClusterTable(region) && hasClusterLengthColumn(this.tables[region.table]),
      );
    }
    return this.allOptions.regions;
  }

  private getData(region: GraphOption): Observable<GraphSidebarDatasourceResponse> {
    if (region.table) {
      return this.dataService.getTableUIIndexState(this.documentID, region.table).pipe(
        take(1),
        switchMap((state) => {
          if (state.currentIndexState === IndexStateEnum.OPEN) {
            return this.getBarchartData(region);
          } else {
            return of({
              documentID: this.documentID,
              reIndexingRequiredTables: [region.table],
              controls: this.generateControls(region.name),
            } as GraphDatasourceReIndexingRequired);
          }
        }),
      );
    } else {
      return this.getBarchartData(region);
    }
  }

  private getBarchartData(region: GraphOption) {
    return this.dataService
      .getBarChartData(this.params, this.documentID, region)
      .pipe(map((chartData) => this.formatData(chartData, region.name, this.params)));
  }

  private formatData(
    chart: IBarChartInfo,
    region: string,
    params: SummaryGraphParams,
  ): GraphSidebarDatasourceResponse {
    const controls = this.generateControls(region);
    if (chart?.data?.length === 0) {
      return { error: `No sequences found for ${region}.`, controls };
    }

    if (chart?.data?.every((sequence) => sequence.data.length === 0)) {
      if (
        params.source === 'json' &&
        params.jsonChart === 'clusters' &&
        params.type === 'nucleotide'
      ) {
        return {
          error:
            'There is no Nucleotide Clusters data for this result. To view this graph you will need to re-run your analysis.',
          controls,
        };
      } else {
        return {
          error: 'There is no data for this graph.',
          controls,
        };
      }
    }

    return {
      graph: {
        data: chart.data,
        title: chart.title,
        xAxisTitle: chart.xLabel,
        yAxisTitle: chart.yLabel,
        type: GraphTypeEnum.COLUMN,
      },
      controls,
      options: {
        region: this.regions.find((r) => r.name === region),
      },
    };
  }

  private generateControls(region: string): GraphSidebarControls {
    const label = (() => {
      if (this.isTypeEquals('cluster_diversity', 'cluster_sizes')) {
        return 'Gene/Region';
      }
      if (this.isJsonChartEquals('annotationRates', 'genes', 'clusters')) {
        return 'Filter By';
      }

      return 'Region';
    })();
    return [
      {
        name: 'region',
        label: label,
        type: GraphControlTypeEnum.SELECT,
        defaultOption: region,
        options: this.regions.map(({ name }) => {
          return {
            displayName: name,
            value: name,
          };
        }),
      },
    ];
  }

  private isJsonChartEquals(...names: string[]) {
    return this.isEquals(this.params.jsonChart, ...names);
  }

  private isTypeEquals(...names: string[]) {
    return this.isEquals(this.params.type, ...names);
  }

  private isEquals(input: string, ...names: string[]) {
    return names.some((name) => input === name);
  }
}
